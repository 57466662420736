.contact-info {
    display: grid;
    grid-template-columns: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-rows: 2;
}

@media screen and (min-width: 1025px) {
    article {
        display: flex;
    }
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.tituloContato h1 {
    padding-bottom: 12px;
    border-bottom: 2px solid #ffb937;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.phones, .form-container, .map-container {
    flex: 1 1 30%;
    margin: 10px;
}

h2 {
    margin-bottom: 10px;
}

p {
    margin-bottom: 10px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
}

input, textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

textarea {
    resize: vertical;
}

button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.map-container iframe {
    width: 100%;
    border-radius: 5px;
}