footer {
    border-top: 1px solid rgb(209, 209, 209);
}

.footerContainer {
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .footerRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .footerCol {
    flex: 1;
    padding: 10px;
    padding-top: 0;
  }
  
  .socialIcons {
    display: flex;
    gap: 10px;
  }
  
  .socialIcon {
    color: inherit; /* herda a cor especificada inline */
  }
  
  .footerLinks,
  .footerLegal {
    list-style: none;
    padding: 0;
  }
  
  .footerLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .footerLink:hover {
    text-decoration: underline;
  }
  
  .direitos {
    text-align: center;
    margin-top: 20px;
  }  

@media screen and (max-width: 600px) {
  a.footerLink {
    font-size: 15px;
  }
}