#header {
    padding: 5px;
    padding-top: 0;
    padding-bottom: 5px;
    background-color: white;
    border-bottom: 2px solid #2D2A7Bff;
    height: 15vh;
    z-index: 1000;
}

#logo {
    width: 15vh; /* 25% da largura da viewport */
    max-width: 140px; /* Largura máxima da imagem para evitar que fique muito grande em telas maiores */
    height: auto;
    padding: 10px;
    margin-top: 5px;
    border-radius: 50%; /* Se a imagem é um círculo, use 50% */
}

@media (max-width: 991px) {
    #logo {
        margin-top: -5px;
    }
}

#toggle .nav-link {
    color: black;
    background-color: black;
    border-color: black
  } 

#toggle {
    border-color: black;
    position: absolute;
    right: 20px;
}

#bars {
    color: black;
}

#white {
    color: black;
    transition: color 0.3s ease;
}

#white:hover {
    color: black;
}

a#white {
    padding-left: 20px;
    color: black;
}

a#white:hover {
    color: black;
}

#underline {
    text-decoration: underline solid transparent;
    transition: text-decoration 0.3s ease;
}

a#white:hover #underline, a#white:active #underline {
    text-decoration: underline solid #fca404;
}

@media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav {
        background-color: white;
        border: 1px solid black;
        border-radius: 10px;
        position: absolute;
        right: 10px;
        top: 110px;
        padding-right: 10px;
    }

    a#white {
        color: black;
        padding-left: 10px;
    }

    a#white:hover {
        color: rgb(60, 60, 60);
    }
}

.navbar-nav .dropdown-menu {
    margin-left: 10px;
}

.navbar-nav .dropdown-menu .dropdown-item {
    text-decoration: underline solid transparent;
    transition: text-decoration 0.3s ease;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
    text-decoration: underline solid #fca404;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.nav-item.show span #underline {
    text-decoration: underline solid #fca404;
}

a#white.dropdown-toggle.nav-link::after {
    display: none;
}

#icone {
    margin-left: 5px;
    transition: all 0.2s ease;
}

.rotate180 {
    transform: rotate(180deg);
}