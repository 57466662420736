.team-section {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.team-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.team-member {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.team-member:hover {
  transform: scale(1.05);
}

.professor-image {
  width: 200px;
  height: 220px;
  object-fit: cover;
  object-position: 0% 35%;
  border-radius: 8px;
  display: block;
}

/* LIGHTBOX */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
  padding: 20px;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
}

.close-button, .prev-button, .next-button {
  background-color: #ffffff;
  color: black;
  border: none;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.close-button {
  color: white;
  font-size: 22px;
}

.category-label {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.prev-button:hover, .next-button:hover {
  background-color: aquamarine;
  transform: scale(1);
  transform: translateY(-50%);
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/* RESPONSIVIDADE */

/* Tamanho de tela menor que 768px */
@media (max-width: 768px) {
  .professor-image {
    width: 150px;
    height: 170px;
  }

  .team-grid {
    gap: 15px;
  }

  .team-section h2 {
    font-size: 1.8em;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 70%;
  }

  .close-button, .prev-button, .next-button {
    font-size: 14px;
    padding: 8px;
  }

  .category-label {
    font-size: 1.2em;
  }
}

/* Tamanho de tela menor que 480px */
@media (max-width: 480px) {
  .professor-image {
    width: 120px;
    height: 140px;
  }

  .team-section h2 {
    font-size: 1.5em;
  }

  .team-grid {
    gap: 10px;
  }

  .lightbox-image {
    max-width: 95%;
    max-height: 60%;
  }

  .close-button, .prev-button, .next-button {
    font-size: 12px;
    padding: 6px;
  }

  .category-label {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .button-text {
    display: none;
  }
}
