body {
    margin: 0;
    padding: 0;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  header h2 {
    margin: 0;
    width: 100%;
    background-color: #2d2a7b;
    color: #fff;
    font-size: 2.5em;
  }

  .calendario {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .imagens-calend {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    row-gap: 20px;
  }

  .calendario img {
    width: 48%;
    max-width: 650px;
    margin: auto;
  }

  .imagens-calend img {
    width: 60%;
  }

  .calendario h2 {
    width: fit-content;
  }

  .tituloEscola {
    width: 100%;
    padding: 10px;
    background-color: #2d2a7b;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
  }
  
  header p {
    font-size: 1.2em;
    color: #555;
  }
  
  .ensino {
    margin-bottom: 40px;
  }
  
  .ensino h2 {
    color: #333;
  }

  .sistemaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sistemaContainer a {
    text-decoration: none;
    color: #7229e6;
  }

  .itensSistema {
    display: flex;
    width: 10vw;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: transform 0.2s ease, color 0.2s ease;
  }

  .itensSistema:hover {
    cursor: pointer;
    color: #6314e2;
    transform: scale(1.02);
  }

  .sistemaContainer img {
    width: 80px;
    border-radius: 10px;
  }
  
  .ensino ul, .ensino ol {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .ensino img {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  
  .atividades {
    margin-bottom: 40px;
  }
  
  .cartas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .carta {
    background-color: #fca404;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 25px;
    width: 40%;
    height: 25vh;
    margin: 0 auto;
    text-shadow: -1px -1px 0 rgb(117, 117, 117), 1px -1px 0 rgb(117, 117, 117), -1px 1px 0 rgb(117, 117, 117), 1px 1px 0 rgb(117, 117, 117);
    text-align: center;
    transition: transform 0.2s;
  }

  .carta:hover {
    transform: scale(1.06);
  }
  
  .carta h3 {
    color: #fff;
  }
  
  .carta p {
    color: #555;
  }
  
  .faq {
    margin-bottom: 40px;
  }
  
  .faq details {
    margin-bottom: 10px;
  }
  
  .faq summary {
    font-weight: bold;
    cursor: pointer;
  }
  
  .faq p {
    margin: 5px 0 0 20px;
    color: #555;
  }

  .livros {
    margin-bottom: 40px;
  }
  
  @media screen and (max-width: 600px) {
    .cartas {
      flex-direction: column;
      justify-content: center;
    }
    .carta {
      width: 80%;
      margin: 10px auto;    
    }
  }