body {
    margin: 0;
    padding: 0;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  header p {
    font-size: 1.2em;
    color: #555;
  }

  .objetivos {
    margin: 20px 0;
    margin-bottom: 60px;
  }
  
  .objetivos h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .itens {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .objetivo {
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d2a7b; /* cor laranja */
    padding: 20px;
    border-radius: 10px;
    width: 270px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .objetivo p {
    margin: 0;
    font-size: 16px;
    color: white;
  }
  
  
  .ensino {
    margin-bottom: 40px;
  }
  
  .ensino h2 {
    color: #333;
  }
  
  .ensino ul, .ensino ol {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .ensino img {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .sistemaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sistemaContainer a {
    text-decoration: none;
    font-weight: bold;
    color: #7229e6;
  }

  .itensSistema {
    display: flex;
    width: 10vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: transform 0.2s ease, color 0.2s ease;
  }

  .itensSistema:hover {
    cursor: pointer;
    color: #6314e2;
    transform: scale(1.02);
  }

  .sistemaContainer img {
    width: 70px;
    border-radius: 10px;
  }
  
  .atividades {
    margin-bottom: 40px;
  }
  
  .cards {
    display: flex;
    justify-content: space-between;
  }
  
  .card p {
    color: #555;
  }
  
  .faq {
    margin-bottom: 40px;
  }
  
  .faq details {
    margin-bottom: 10px;
  }
  
  .faq summary {
    font-weight: bold;
    cursor: pointer;
  }
  
  .faq p {
    margin: 5px 0 0 20px;
    color: #555;
  }
  
  @media screen and (max-width: 600px) {
    .itens {
      flex-direction: column;
    }
    
    .objetivo {
      margin: 5px auto;
    }
  }