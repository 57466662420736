/* src/components/Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 15px;
    cursor: pointer;
    z-index: 10;
  }

  .close-button:hover {
    background: rgb(228, 42, 42);
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-text {
    flex: 1;
    margin-right: 20px;
  }
  
  .modal-image img {
    width: 40vh;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .modal-video {
    display: flex;
    justify-content: center;
  }
  
  .modal-image img {
    max-width: 100%;
    height: auto;
  }  
  
@media screen and (max-width: 800px) {
    .modal-body {
        flex-direction: column;
    }

    .modal-image {
        margin-top: 20px;
    }
}