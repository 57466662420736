.conteudo {
    margin: 10px;
}

.view-all-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin: 20px 0;
    transition: transform 0.2s;
}

.view-all-button:hover {
    transform: scale(1.03);
    background-color: #0056b3;
}