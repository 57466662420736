/* Estilos gerais */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  a {
    text-decoration: none;
    color: #fff;
  }
  
  /* Hero Section */
  .hero {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2d2a7b;
    display: flex;
    justify-content: center;
    align-items: end;
    height: 70vh;
    background-position: center;
    color: white;
    text-align: center;
    padding: 30px 20px;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
    margin: 0;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .cta-button {
    background-color: #30ae35;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }

  .texto {
    padding: 0 10px;
  }

  .texto p {
    font-size: 1.07em;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
    background-color: rgb(82, 190, 87);
  }
  
  /* Benefícios Section */
  .benefits {
    background-color: #f4f4f4;
    padding: 0 15123
    px 20px;
    margin: 0;
    text-align: center;
  }
  
  .benefits h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .benefit-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .card-mat {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    width: 85%;
    max-width: 1000px;
    flex-wrap: wrap;
    height: 100%;
    padding: 0;
  }
  
  .card-content-mat {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    flex-direction: column;
    padding: 10px;
  }
  
  .image-content-mat {
    flex: 2;
    width: 100%;
    height: 50vh;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }  

  .card-mat:nth-child(2) {
    flex-direction: row-reverse;
  }

  .image-content-mat img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .icon {
    font-size: 3rem;
    color: #fca404;
    margin-bottom: 20px;
  }
  
  .card-mat h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .card-mat p {
    font-size: 1rem;
    color: #555;
  }

  .testimonial-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    max-width: 300px;
    text-align: left;
    height: 100%;
    min-height: 200px;
}

#separador-matricula {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #2d2a7b;
  height: 100%;
  color: #fdd600; /* Texto amarelo */
  font-size: 1em;
  padding: 50px 100px; /* Espaçamento interno */
}

.stat-item {
  text-align: center;
}

.stat-item p {
  color: white;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
}

.testimonial-content {
  flex-grow: 1; /* Permite que o conteúdo ocupe o espaço restante */
}

.testimonial-content p {
    font-style: italic;
    color: #555;
}

.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: auto; /* Empurra para o fundo do cartão */
}

.author-initials {
    background-color: #2c3e50;
    color: white;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 10px;
}

.author-details {
    text-align: left;
}

.author-details strong {
    display: block;
    color: #333;
    font-size: 16px;
}

.author-details span {
    font-size: 14px;
    color: #777;
}


  /* Adicione isso ao seu Matricula.css */
.instagram-media {
  background: #FFF;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 1px 10px rgba(0, 0, 0, 0.15);
  margin: 1px;
  max-width: 240px;
  min-width: 326px;
}

  
  .testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .testimonial {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }
  
  .testimonial p {
    font-size: 1rem;
    font-style: italic;
    color: #666;
  }
  
  .testimonial span {
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Seção de Diferenciais */
  .extra-info {
    background-color: #f4f4f4;
    padding: 30px 20px;
    padding-top: 20px;
    text-align: center;
  }

  #diferenciais {
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .extra-info h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .info-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .info-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .info-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .info-card p {
    font-size: 1rem;
    color: #555;
  }  

  #botao-mobile {
    display: none;
  }

  .texto {
    padding: 30px 20px;
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
  }
  
  .texto-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .texto h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2d2a7b;
  }
  
  .texto-paragrafo {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }  

  @media screen and (max-width: 1200px) {
    .hero {
        height: 50vw;
    }

    #diferenciais { 
      padding-top: 0;
    }

    #botao-mobile a {
        display: flex;
        text-align: center;
        margin: -40px 0 30px 0;
        padding: 5px 10px;
        font-size: 0.8em;
    }

    .hero a {
        display: none;
    }
  }

  @media screen and (max-width: 800px) {
    .card-mat {
      width: 100%;
      flex-direction: column;
    }
  }

@media screen and (max-width: 500px) {
  .stat-number {
    font-size: 4rem;
  }
  .stat-item {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 800px) {
  .card-mat:nth-child(2) {
    flex-direction: column;
  }

  .image-content-mat {
    height: 30vh;
  }

  .card-content-mat { 
    margin-top: 15px;
  }
}