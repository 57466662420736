.books-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 60px;
    column-gap: 10px;
    width: 100%;
    margin-bottom: 30px;
}

.pdf-botao {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Cor de fundo azul */
    color: white; /* Cor do texto */
    text-align: center;
    width: 60%;
    margin-bottom: 20px;
    font-size: 16px;
    text-decoration: none; /* Remove o sublinhado do link */
    border-radius: 5px; /* Bordas arredondadas */
    transition: background-color 0.3s, transform 0.2s; /* Efeito de transição */
  }
  
  .pdf-botao:hover {
    background-color: #0056b3; /* Cor de fundo ao passar o mouse */
    transform: scale(1.05); /* Leve aumento no botão ao passar o mouse */
  }
  
  .pdf-botao:active {
    background-color: #004085; /* Cor de fundo quando clicado */
  }
  

.book-item {
    border-radius: 15px;
    text-align: center;
    width: 22vw;
    background-color: #fff;
    box-shadow: 5px 10px #e0dddd;
    padding: 20px;
    height: 65vh;
    max-height: 470px;
}

@media screen and (max-height: 700px) {
    .book-item {
        height: 80vh;
    }
}

@media screen and (max-width: 1200px) {
    .book-item {
        width: 30vw;
    }    
}

@media screen and (max-width: 840px) {
    .book-item {
        width: 45vw;
    }    
}

@media screen and (max-width: 450px) {
    .book-item {
        width: 80vw;
    }    
}