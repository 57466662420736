.conhecaEquipe {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 0 auto;
    width: 80vw;
    text-align: center;
    padding: 20px;
}

.membroEquipe h3 {
    margin: 10px 0 5px;
}

.membroEquipe .imagem {
    width: 100%; /* ou a largura que você deseja */
    height: 55vh; /* ou a altura que você deseja para exibir a metade superior */
    min-height: 360px;
    overflow: hidden; /* oculta a parte da imagem que não cabe no contêiner */
}

.membroEquipe .imagem img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: cover; /* ajusta a imagem para cobrir o contêiner */
}

.membroEquipe p {
    text-align: center;
}

.botaoContainerProfessores {
    margin-top: 20px;
}

.botaoEquipe {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.botaoEquipe:hover {
    background-color: #0056b3;
}

.membroEquipe {
    width: 50%
}