.livros-carousel {
  padding: 20px;
  background-color: #f5f5f5;
}

.livros-carousel p {
  text-align: center;
}

.swiper {
  width: 95%;
  height: 100%;
  border: 1px solid rgba(152, 152, 152, 0.2);
}

.swiper-button-prev, .swiper-button-next {
  color: black;
  transform: scale(0.6);
}

.filters {
  display: flex;
  justify-content: left;
  gap: 30px;
  margin-bottom: 20px;
}

.filters .grade-select,
.filters .type-select {
  width: 20vw;
  margin-right: 10px;
}

.filters label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.filters select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.livro-slide {
  padding: 10px;
  height: 70vh;
  display: flex;
  align-items: top;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.livro-files {
  list-style: none;
  padding: 0;
  margin: 0;
}

.livro-file {
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
}

.livro-image {
  width: 10vw;
  height: 25vh;
  margin: auto;
  border-bottom: 1px solid #ddd;
}

.livro-text {
  width: 95%;
  padding: 10px;
  text-align: center;
}

.livro-text-content {
  text-align: center;
}

.livro-name {
  font-size: 18px;
  margin: 0 0 10px;
}

.livro-author,
.livro-pub-year,
.livro-year,
.livro-series {
  font-size: 14px;
  margin: 5px 0;
}

@media screen and (max-width: 700px) {
  .livro-image {
    width: 35vw;
    margin: auto;
    border-bottom: 1px solid #ddd;
  }

  .swiper-button-prev, .swiper-button-next {
    transform: scale(0.5);
    top: 32%;
  }  
}

@media screen and (max-width: 768px) {
  .filters .grade-select,
  .filters .type-select {
    width: 70vw;
  }
}

/* Estilo para os botões de navegação do Swiper */
