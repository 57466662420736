.video-container {
    display: flex;
    justify-content: center;
  }

.video-responsive {
    position: relative;
    overflow: hidden;
    width: 60vw; /* Largura de 60% da viewport */
    padding-top: 33.75%; /* Altura para manter a proporção 16:9 */
  }
  
  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 80vw; /* Ajusta a largura do vídeo */
    height: 45vw; /* Mantém a proporção 16:9 */
    max-width: 100%;
    max-height: 100%;
    border: none;
    border-radius: 0 0 10px 10px; /* Bordas arredondadas na parte inferior */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
@media screen and (max-width: 660px) {
    .video-responsive {
        width: 90vw; /* Aumenta a largura do vídeo para 90% da viewport */
        padding-top: 50.625%; /* Ajusta a altura para manter a proporção 16:9 */
      }
}

@media screen and (max-width: 1024px) and (min-width: 661px){
    .video-responsive {
        width: 70vw; /* Aumenta a largura do vídeo para 90% da viewport */
        padding-top: 39.375%; /* Ajusta a altura para manter a proporção 16:9 */
      }
}