body, html {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Fundo cinza claro */
}

.escola {
  width: 98%;
  margin: 20px auto;
  margin-top: 0;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  margin: 0;
  font-size: 2.5em;
  color: #333;
}

header p {
  font-size: 1.2em;
  color: #555;
}

.ensino, .atividades, .faq, .galeria, .materiais {
  margin-bottom: 40px;
}

.card-content {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.card-content-text {
  flex: 1;
  padding-right: 20px;
}

.card-content-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

h2 {
  color: #333;
  font-size: 2em;
  border-bottom: 2px solid #fca404; /* Linha laranja abaixo do título */
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.imagens {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.imagens img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .imagens {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.ensino p, .card p, .faq p {
  font-size: 1em;
  color: #555;
  text-align: justify;
  padding: 0 10px;
  line-height: 1.6;
}

.ensino ul, .ensino ol {
  margin: 10px 0;
  padding-left: 20px;
}

.ensino img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sistemaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sistemaContainer a {
  text-decoration: none;
  color: #7229e6;
}

.itensSistema {
  display: flex;
  width: 10vw;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: transform 0.2s ease, color 0.2s ease;
}

.itensSistema:hover {
  cursor: pointer;
  color: #6314e2;
  transform: scale(1.02);
}

.poliedro {
  width: 60px;
  border-radius: 0px;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.card {
  background-color: #2d2a7b;
  color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 30%;
  height: 25vh;
  text-align: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.color1 {
  background-color: #ff4b3a; /* Laranja Avermelhado */
}

.color2 {
  background-color: #4f368d; /* Roxo Profundo */
}

.color3 {
  background-color: #78ad29; /* Verde Oliva */
}

.color4 {
  background-color: #FF33A6 ; /* Laranja Avermelhado */
}

.color5 {
  background-color: #33FF57 ; /* Roxo Profundo */
}

.color6 {
  background-color: #FF4500 ; /* Verde Oliva */
}

.color7 {
  background-color: #1E90FF;
}

.color8 {
  background-color: #32CD32;
}

.faq details {
  margin-bottom: 10px;
}

.faq summary {
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 600px) {
  header h1 {
      font-size: 2em;
  }

  header p {
      font-size: 1em;
  }

  .cards {
      flex-direction: column;
      align-items: center;
  }

  .card {
      width: 100%;
      margin-bottom: 20px;
  }

  .ensino img {
      width: 80%; /* Ajusta para 80% da largura da viewport em telas menores */
  }
}
