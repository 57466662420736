.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}

.whatsapp-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    padding-top: 8px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
    background-color: #18cd40; /* Cor do ícone do WhatsApp */
}

.whatsapp-link i {
    margin-right: 5px;
    font-size: 22px; /* Tamanho do ícone */
}

@media screen and (max-width: 900px) {
    #texto {
        display: none;
    }

    .whatsapp-link i {
        margin-right: 0;
        font-size: 26px; /* Tamanho do ícone */
    }
}