@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    height: 100%;
    background: linear-gradient(to right, #f4f4f4, #e2e2e2);
    color: #333;
}

.card-container {
    display: flex;
    flex-wrap: wrap; /* Permite que os cards se movam para a próxima linha se não houver espaço */
    gap: 20px; /* Espaçamento entre os cards */
    justify-content: center; /* Centraliza os cards */
}

.carta-inicio {
    width: calc(33.333% - 20px); /* Cada card ocupará um terço da largura, menos o espaço do gap */
    perspective: 1000px; /* Perspectiva para o efeito 3D */
}

.card-inner {
    position: relative; /* Para o efeito de flip */
    transition: transform 0.6s; /* Transição suave para o flip */
    transform-style: preserve-3d; /* Mantém o estilo 3D dos filhos */
}

.carta-inicio:hover .card-inner {
    transform: rotateY(180deg); /* Rotaciona a carta ao passar o mouse */
}

.card-front,
.card-back {
    backface-visibility: hidden; /* Esconde a parte de trás quando não visível */
    border-radius: 20px; /* Bordas arredondadas */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra */
}

.card-front {
    background-color: #f9f9f9; /* Cor de fundo da frente */
}

.card-back {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2D2DAF; /* Fundo semi-transparente */
    color: #fff;
    backdrop-filter: blur(10px); /* Aplica o desfoque ao fundo */
    transform: rotateY(180deg); /* Inicia virada para trás */
    display: flex;
    text-align: center;
    justify-content: center; /* Centraliza conteúdo na parte de trás */
    align-items: center; /* Centraliza verticalmente */
    padding: 15px; /* Adiciona algum preenchimento ao conteúdo */
}

.card-back h1, .card-back h2, .card-back h3 {
    color: #fff;
}

.carta-inicio:hover {
    cursor: pointer;
}

.card-content {
    padding: 15px;
}

.card-content img {
    border-radius: 20px;
}

.carta-inicio img {
    width: 100%; /* Imagem ocupa toda a largura do card */
    height: auto; /* Mantém a proporção da imagem */
}

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .elemento-animado {
    opacity: 0;
    transform: translateY(45px);
    transition: all 0.5s ease-in-out;
  }

  .elemento-animado-down {
    opacity: 0;
    transform: translateY(-45px);
    transition: all 0.5s ease-in-out;
  }

  .elemento-animado-esquerda {
    opacity: 0;
    transform: translateX(-90px);
    transition: all 0.5s ease-in-out;
  }

  .elemento-animado-direita {
    opacity: 0;
    transform: translateX(90px);
    transition: all 0.5s ease-in-out;
  }
  
  .elemento-animado.entrou-na-tela, .elemento-animado-esquerda.entrou-na-tela, .elemento-animado-direita.entrou-na-tela, .elemento-animado-down.entrou-na-tela {
    opacity: 1;
    transform: translateY(0);
  }  

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat';
}

/* Navbar */
.navbar {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
}

/* Container Principal */
.sobrenos-container {
    text-align: center;
    padding-bottom: 10px;
    background-color: #ffffff; /* Fundo branco para contraste */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 90vw;
}

/* Imagem Container */
.imagem-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.imagem-container img {
    width: 20vw;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
}

/* Botões */
.botoes {
    display: flex;
    margin-top: -40px;
    gap: 20px;
}

button {
    transition: transform 0.3s ease-in-out;
}

button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

/* Fundo */
.fundo {
    background: linear-gradient(90deg, rgba(252, 164, 4, 1) 0%, rgba(255, 191, 0, 1) 100%);
    display: inline-block;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    text-align: center;
    width: 100%;
    padding: 20px;
}

.fundo h1 {
    font-family: 'Roboto', sans-serif;
    font-size: min(4vw, 38px);
    color: white;
    margin: 0;
}

/* Vídeo */
.video {
    padding: 20px;
}

/* Grid Container */
.grid-container {
    display: grid;
    gap: 20px;
    padding: 20px;
}

/* Grid Item */
.grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    transition: transform 0.3s ease-in-out;
}

.grid-item.alternado {
    border-top: 0;
}

#textoAlternado {
    padding: 0 10px;
}

/* Imagem */
.imagem img, .grid-imagem img {
    width: 70vw;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Linha Amarela */
.amarelo {
    margin: 20px auto;
    border-color: #fca404;
    width: 90%;
    height: 2px;
    background: #fca404;
    opacity: 100%;
}

.alunoOnline {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30vh; /* Full height to center the content vertically */
}

.centralAlunoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;
    text-align: center;
}

.imagemAluno {
    width: 100px; /* Ajuste o tamanho conforme necessário */
    position: relative;
    z-index: 1;
    margin-right: -57.5px; /* Ajuste conforme necessário para mover a imagem para a esquerda */
}

.botaoContainer {
    display: flex;
    background-color: #2D2DAF;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centralAluno {
    background-color: #fca404; /* Background color similar to your design */
    color: white; /* Text color */
    font-size: 1.1em; /* Increase font size */
    font-weight: bold; /* Bold text */
    padding: 20px 40px; /* Padding for the button */
    border: none; /* Remove default border */
    border-radius: 50px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.centralAluno:hover {
    background-color: #ffb937; /* Darker background color on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.subTitulo {
    margin-top: 10px;
    font-size: 1em;
    color: #ffffff;
}

.fundoInicio {
    padding-bottom: 25px;
    padding-top: 5px;
    position: relative;
    background-color: #ffb937;
    overflow: hidden;
}

.onda {
    position: absolute;
    width: 100%;
    height: 40vh;
    left: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxNjAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDgwQzQwMCAxMTAgODAwIDMwIDEyMDAgNjBDMTYwMCA5MCAxNjAwIDAgMTYwMCAwSDAtLjFMMCA4MFoiIGZpbGw9IiMyZDJkYWYiLz4KPC9zdmc+') no-repeat;
    background-size: cover;
}

@media screen and (max-width: 800px) {
    .onda {
        height: 35vw;
    }
}

.onda.onda-top {
    top: 0;
}

/* Media Queries */
@media screen and (max-width: 600px) {
    .sobrenos-container h2 {
        font-size: 30px;
    }
    
    .sobrenos-container p {
        font-size: 18px;
        margin: auto;
    }

    .imagem-container img {
        width: 60vw;
    }

    .fundo {
        width: 90vw;
    }

    .botoes {
        display: flex;
        margin-top: -40px;
        gap: 10px;
    }

    .fundo h1 {
        font-size: 5vw;
    }

    .grid-item img {
        display: none;
    }

    .imagem-container img {
        width: 40vw;
    }

    .botaoContainer button {
        width: 90%;
    }

    .conhecaEquipe h2 {
        font-size: 28px;
    }

    .botoes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .medio img {
        width: 50vw;
    }

    .fundoInicio {    
        padding-top: 0;
    }
}

.redirecionamento-container {
    padding-top: 40px;
    padding-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Grid Item - Large Screens */
@media screen and (min-width: 1025px) {
    .grid-item {
        grid-template-columns: repeat(2, 500px);
        grid-auto-rows: minmax(500px, auto);
    }
    
    .grid-item.alternado .texto {
        order: 2;
    }

    .imagem-container img {
        width: 25vw;
    }

    .alunoOnline {
        height: 40vh; /* Full height to center the content vertically */
    }

    .centralAlunoContainer {
        width: 44%;
    }    

    .imagemAluno {
        width: 150px; /* Ajuste o tamanho conforme necessário */
        margin-right: -84.7px; /* Ajuste conforme necessário para mover a imagem para a esquerda */
    }
    
}

/* Content */
.content {
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.botao {
    text-align: center;
}

/* Vídeo */
.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.app-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;    
}

.app-wrapper img {
    width: 16vw;
}

@media screen and (max-width: 768px){
    .app-wrapper img {
        width: 20vw;
    }
}

@media screen and (max-width: 768px){
    .card-content {
        padding: 5px;
    }

    .carta-inicio {
        width: 45%;
    }
}

/* Social Icons */
.social-icons {
    text-align: center;
    margin-top: 20px;
}

.social-icons .icon {
    margin: 0 10px;
    transition: transform 0.2s ease-in-out;
    display: inline-block;
}

.social-icons .icon:hover {
    transform: scale(1.167);
}

.social-icons .icon a {
    color: black;
    font-size: 24px;
    display: inline-block;
}

/* Headers */
h1, h2, h3 {
    color: #fca404;
}

/* Text */
p {
    color: #666;
    line-height: 1.6;
}
