.carousel {
  width: 90vw;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center;
  position: relative;
}

.carousel-item img {
  margin: auto;
  width: 100%;
}

div.carousel.slide {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.carousel-control-p,
.carousel-control-n {
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
color: white;
}

.carousel-control-p svg,
.carousel-control-n svg {
width: 2vw; /* Ajusta o tamanho do ícone */
height: auto; /* Mantém a proporção do ícone */
color: white;
}

.carousel-control-prev,
.carousel-control-next {
width: auto; /* Ajusta a largura dos controles */
height: 10%;
position: absolute; /* Posicionamento absoluto */
  top: 50%; /* Centraliza verticalmente */
  transform: translateY(-50%); /* Centraliza verticalmente */
  z-index: 1; /* Garante que os controles fiquem acima do conteúdo */

}

a.carousel-control-prev {
  opacity: 100%;
  border-bottom-left-radius: 5px;
  width: 5%;
  left: -5%;
  transition: background-color 0.2s ease, opacity 0.2s ease;
}

a.carousel-control-next {
  opacity: 100%;
  border-bottom-right-radius: 5px;
  width: 5%;
  right: -5%;
  transition: background-color 0.2s ease, opacity 0.2s ease;
} 

div.carousel-indicators {
  bottom: -40px; /* Ajusta a posição vertical dos indicadores */
  z-index: 2; /* Garante que os indicadores fiquem acima do conteúdo */
}

div.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo dos indicadores */
}

div.carousel-indicators .active {
  background-color: white; /* Cor do indicador ativo */
}

@media screen and (min-width: 1024px) {
  .carousel {
      width: 65vw
  }
}